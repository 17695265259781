import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';

// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import InfoArea from 'components/InfoArea/InfoArea.js';

import featuresStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/featuresStyle.js';

import SerendipityCarousel from 'views/LandingPage/Sections/SerendipityCarousel';

import { Dashboard, CloudUpload, Storage, Devices } from '@material-ui/icons';
import * as landingCopy from 'assets/copy/LandingPageCopy.js';
const featureStyles = makeStyles(featuresStyle);

export default function SectionProduct({ ...rest }) {
    const featureClass = featureStyles();

    return (
        <div className={featureStyles.container} {...rest}>
            <div className={featureClass.features3}>
                <GridContainer>
                    <GridItem
                        xs={12}
                        sm={6}
                        md={8}
                        className={
                            featureClass.mlAuto +
                            ' ' +
                            featureClass.mrAuto +
                            ' ' +
                            featureClass.textCenter
                        }
                    >
                        <h2 className={featureClass.title}>
                            {landingCopy.featureHero}
                        </h2>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={3}>
                        <InfoArea
                            className={featureClass.infoArea}
                            icon={Dashboard}
                            title={landingCopy.primaryFeature}
                            description={landingCopy.primaryFeatureSub}
                            iconColor="info"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <InfoArea
                            className={featureClass.infoArea}
                            icon={Storage}
                            title={landingCopy.tertiaryFeature}
                            description={landingCopy.tertiaryFeatureSub}
                            iconColor="rose"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <InfoArea
                            className={featureClass.infoArea}
                            icon={CloudUpload}
                            title={landingCopy.secondaryFeature}
                            description={landingCopy.secondaryFeatureSub}
                            iconColor="success"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                        <InfoArea
                            className={featureClass.infoArea}
                            icon={Devices}
                            title={landingCopy.quadFeature}
                            description={landingCopy.quadFeatureSub}
                            iconColor="warning"
                        />
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12} sm={12}>
                        <SerendipityCarousel />
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
