import React from 'react';
// react component for creating beautiful carousel
import Carousel from 'react-slick';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';

import carouselStyle from 'assets/jss/material-kit-pro-react/views/componentsSections/carouselStyle.js';

import landingDash from 'assets/img/sections/landing-page-desktop.png';
import nightscoutDash from 'assets/img/sections/nightscout-dash.png';
import serendipityReports from 'assets/img/sections/serendipity-reports.png';

const useStyles = makeStyles(carouselStyle);

export default function SerendipityCarousel() {
    const classes = useStyles();
    const settings = {
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    };
    return (
        <div className={classes.section} id="carousel">
            <div className={classes.container}>
                <GridContainer>
                    <GridItem md={12} className={classes.marginAuto}>
                        <Card>
                            <Carousel {...settings}>
                                <div>
                                    <img
                                        src={landingDash}
                                        alt="Serendipity Dashboard"
                                        className="slick-image"
                                    />
                                </div>
                                <div>
                                    <img
                                        src={serendipityReports}
                                        alt="Serendipity Reports"
                                        className="slick-image"
                                    />
                                </div>
                                <div>
                                    <img
                                        src={nightscoutDash}
                                        alt="Nightscout Dashboard"
                                        className="slick-image"
                                    />
                                </div>
                            </Carousel>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
}
