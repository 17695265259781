import React, { useState } from 'react';

import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import CustomInput from 'components/CustomInput/CustomInput.js';
import Button from 'components/CustomButtons/Button.js';
import CustomTabs from 'components/CustomTabs/CustomTabs.js';

import { FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

import { SettingsApplications } from '@material-ui/icons';

// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
import styles from 'assets/jss/material-kit-pro-react/views/settingsPageStyle';

const useStyles = makeStyles(styles);

export default function FreeStyleSettings(props) {
    const classes = useStyles();

    const { handleContextUpdate, userPreferences } = props;

    const { abbott } = userPreferences;

    const { ABBOTT_USER_NAME, ABBOTT_PASSWORD, ABBOTT_API_REGION } =
        abbott || {};

    const [abbottSettings, setAbbottSettings] = useState({
        ABBOTT_USER_NAME: ABBOTT_USER_NAME || '',
        ABBOTT_PASSWORD: ABBOTT_PASSWORD || '',
        ABBOTT_API_REGION: ABBOTT_API_REGION || 'US'
    });

    const [settingsApplied, setSettingsApplied] = useState(true);

    const handleTextInput = async (event) => {
        const { id, value } = event.target;
        setAbbottSettings({ ...abbottSettings, [id]: value });
        setSettingsApplied(false);
    };

    const handleSelectInput = async (event) => {
        const { name, value } = event.target;
        setAbbottSettings({ ...abbottSettings, [name]: value });
        setSettingsApplied(false);
    };

    const handleSettingsSubmit = async () => {
        handleContextUpdate('abbott', { ...abbottSettings });
        setSettingsApplied(true);
    };

    const LIBRE_LINK_SERVER_OPTIONS = [
        { value: 'US', label: 'United States' },
        { value: 'EU', label: 'Europe' }
    ];

    const libreLinkSettingsTab = (
        <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                    formControlProps={{ fullWidth: true }}
                    labelText="LibreLinkUp Username"
                    id="ABBOTT_USER_NAME"
                    name="ABBOTT_USER_NAME"
                    inputProps={{
                        type: 'text',
                        placeholder: 'LibreLinkUp Username',
                        value: abbottSettings.ABBOTT_USER_NAME,
                        onChange: handleTextInput
                    }}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <CustomInput
                    formControlProps={{ fullWidth: true }}
                    labelText="LibreLinkUp Password"
                    id="ABBOTT_PASSWORD"
                    name="ABBOTT_PASSWORD"
                    inputProps={{
                        type: 'password',
                        placeholder: 'LibreLinkUp Password',
                        value: abbottSettings.ABBOTT_PASSWORD,
                        onChange: handleTextInput
                    }}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
                <div>
                    <FormControl fullWidth className={classes.nsSettingSelect}>
                        <InputLabel htmlFor="libre-link-server">
                            LibreLinkUp Server
                        </InputLabel>
                        <Select
                            id="LIBRE_LINK_SERVER"
                            name="LIBRE_LINK_SERVER"
                            value={abbottSettings.ABBOTT_API_REGION}
                            onChange={handleSelectInput}
                            inputProps={{
                                name: 'ABBOTT_API_REGION',
                                id: 'ABBOTT_API_REGION'
                            }}
                        >
                            {LIBRE_LINK_SERVER_OPTIONS.map((serverOption) => {
                                return (
                                    <MenuItem
                                        value={serverOption.value}
                                        key={serverOption.label}
                                    >
                                        {serverOption.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            </GridItem>
        </GridContainer>
    );

    const libreLinkTab = {
        tabName: 'Libre LinkUp Settings',
        tabIcon: SettingsApplications,
        tabContent: libreLinkSettingsTab
    };

    return (
        <div>
            <CustomTabs
                title="Abbott"
                headerColor="success"
                tabs={[libreLinkTab]}
            />
            <Button
                color="info"
                size="lg"
                onClick={() => {
                    handleSettingsSubmit();
                }}
                disabled={settingsApplied}
                className={classes.saveButton}
            >
                Apply Settings{' '}
            </Button>
        </div>
    );
}
