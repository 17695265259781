import React from 'react';
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';
import CardBody from 'components/Card/CardBody.js';
import CardFooter from 'components/Card/CardFooter.js';
import Button from 'components/CustomButtons/Button.js';
import { handlePaymentSubmission, PRODUCT_TYPE } from 'lib/PricingHandler';

import pricingStyle from 'assets/jss/material-kit-pro-react/views/sectionsSections/pricingStyle.js';
import { monthlyTierPricing } from 'assets/copy/LandingPageCopy';
const useStyles = makeStyles(pricingStyle);

export default function SectionSerendipityPricing({ ...rest }) {
    const classes = useStyles();

    const [paymentLoading, setPaymentLoading] = React.useState(false);

    const handlePaymentClick = async (subscriptionType) => {
        setPaymentLoading(true);
        await handlePaymentSubmission(subscriptionType);
    };

    return (
        <div className="cd-section" {...rest}>
            <div className={classes.pricing}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={6}
                            md={8}
                            className={
                                classes.mlAuto +
                                ' ' +
                                classes.mrAuto +
                                ' ' +
                                classes.textCenter
                            }
                        >
                            <h2 className={classes.title}>
                                One plan for Nightscout and your Data.
                            </h2>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={4}
                            md={4}
                            className={
                                classes.mlAuto +
                                ' ' +
                                classes.mrAuto +
                                ' ' +
                                classes.textCenter
                            }
                        >
                            <Card pricing color="success">
                                <CardBody>
                                    <h1 className={classes.cardTitleWhite}>
                                        <small>$</small> {monthlyTierPricing}{' '}
                                        <small>/mo</small>
                                    </h1>
                                    <ul>
                                        <li>We deploy Nightscout for you</li>
                                        <li>Unlimited reporting</li>
                                        <li>Unlimited sharing</li>
                                        <li>Priority access to new features</li>
                                        <li>24x7x365 support</li>
                                    </ul>
                                </CardBody>
                                <CardFooter
                                    pricing
                                    className={classes.justifyContentCenter}
                                >
                                    <Button
                                        color="white"
                                        round
                                        disabled={paymentLoading}
                                        onClick={() => {
                                            handlePaymentClick({
                                                product:
                                                    PRODUCT_TYPE.NIGHTSCOUT_MONTHLY
                                            });
                                        }}
                                    >
                                        Get Started
                                    </Button>
                                </CardFooter>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>
    );
}
